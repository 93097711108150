<template>
  <div id="literature">
    <TopList :navList="navList" @setType="setType" :type="type"></TopList>
    <All v-if="type === 0"></All>
    <rests :name="name" v-else></rests>
  </div>
</template>

<script>
import TopList from "../../components/TopList";
import All from "./conponents/all";
import Rests from "./conponents/rests";
export default {
  name: "index",
  components: {
    TopList,
    All,
    Rests,
  },
  data() {
    return {
      type: 0,
      name: "",
      navList: [
        "全部",
        "期刊论文",
        "会议成果",
        "专利",
        "专著",
        "科技报告",
        "标准",
        "其他",
      ],
    };
  },
  methods: {
    setType(e) {
      this.type = e;
      this.name = this.navList[e];
    },
  },
};
</script>

<style lang="scss" scoped>
#literature {
  min-height: 800px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 10px;
}
</style>
