<template>
  <div id="rests">
    <div class="classify">
      <div class="left">
        <span class="status">状态：</span>
        <span
          @click="setStatusAct(0, null)"
          :class="statusAct === 0 ? 'act' : 'statusList'"
          >全部</span
        >
        <!--        <span-->
        <!--          @click="setStatusAct(1, '已审核')"-->
        <!--          :class="statusAct === 1 ? 'act' : 'statusList'"-->
        <!--        >已审核</span-->
        <!--        >-->
        <span
          @click="setStatusAct(1, 1)"
          :class="statusAct === 1 ? 'act' : 'statusList'"
          >已发布</span
        >
        <span
          @click="setStatusAct(2, 2)"
          :class="statusAct === 2 ? 'act' : 'statusList'"
          >审核中</span
        >
        <span
          @click="setStatusAct(3, 3)"
          :class="statusAct === 3 ? 'act' : 'statusList'"
          >未通过</span
        >
        <span
          @click="setStatusAct(4, 4)"
          :class="statusAct === 4 ? 'act' : 'statusList'"
          >已下架</span
        >
        <span
          @click="setStatusAct(5, 5)"
          :class="statusAct === 5 ? 'act' : 'statusList'"
          >草稿</span
        >
      </div>

      <!--      <div class="left">-->
      <!--        <span class="status">状态：</span>-->
      <!--        <span-->
      <!--          @click="setStatusAct(0, null)"-->
      <!--          :class="statusAct === 0 ? 'act' : 'statusList'"-->
      <!--        >全部</span-->
      <!--        >-->
      <!--        &lt;!&ndash;        <span&ndash;&gt;-->
      <!--        &lt;!&ndash;          @click="setStatusAct(1, '已审核')"&ndash;&gt;-->
      <!--        &lt;!&ndash;          :class="statusAct === 1 ? 'act' : 'statusList'"&ndash;&gt;-->
      <!--        &lt;!&ndash;        >已审核</span&ndash;&gt;-->
      <!--        &lt;!&ndash;        >&ndash;&gt;-->
      <!--        <span-->
      <!--          @click="setStatusAct(1, 1)"-->
      <!--          :class="statusAct === 1 ? 'act' : 'statusList'"-->
      <!--        >已发布</span-->
      <!--        >-->
      <!--        <span-->
      <!--          @click="setStatusAct(2, 2)"-->
      <!--          :class="statusAct === 2 ? 'act' : 'statusList'"-->
      <!--        >审核中</span-->
      <!--        >-->
      <!--        <span-->
      <!--          @click="setStatusAct(3, 3)"-->
      <!--          :class="statusAct === 3 ? 'act' : 'statusList'"-->
      <!--        >未通过</span-->
      <!--        >-->
      <!--        <span-->
      <!--          @click="setStatusAct(4, 4)"-->
      <!--          :class="statusAct === 4 ? 'act' : 'statusList'"-->
      <!--        >已下架</span-->
      <!--        >-->
      <!--        <span-->
      <!--          @click="setStatusAct(5, 5)"-->
      <!--          :class="statusAct === 5 ? 'act' : 'statusList'"-->
      <!--        >草稿</span-->
      <!--        >-->
      <!--      </div>-->
      <div class="right">
        <span class="time">时间：</span>
        <DatePicker
          :value="Timevalue"
          format="yyyy/MM/dd"
          @on-change="handleChange"
          type="daterange"
          split-panels
          placement="bottom-end"
          placeholder="Select date"
          style="width: 220px"
        ></DatePicker>
        <span class="time">标题：</span
        ><Input
          v-model="search"
          placeholder="请输入标题进行搜索"
          class="searchinput"
        >
          <Icon @click="handleClick" type="ios-search" slot="suffix" />
        </Input>
        <input type="text" class="elHidden" />
      </div>
    </div>
    <div class="classify">
      <div class="left">
        <span class="status">属性：</span>
        <span
          @click="setSropertyAct(0, 0)"
          :class="property === 0 ? 'act' : 'statusList'"
          >全部</span
        >
        <span
          @click="setSropertyAct(1, 1)"
          :class="property === 1 ? 'act' : 'statusList'"
          >百家号</span
        >
        <span
          v-for="(item, index) in scienceList"
          :key="item.channel1Id"
          @click="setSropertyAct(index + 2, item)"
          :class="property === index + 2 ? 'act' : 'statusList'"
          >{{ item.channel2Name ? item.channel2Name : item.channel1Name }}</span
        >
      </div>
    </div>
    <div class="list">
      <Spin size="large" fix v-show="spinShow"></Spin>
      <div class="box" :key="item.articleId" v-for="item in list">
        <div class="left">
          <div class="title">
            [{{ item.articleTypeName }}]
            <span v-html="item.title"></span>
            <span
              style="margin-left: 10px; color: #999; font-size: 14px"
              v-if="item.articleType == 0 && item.type == 1"
              >会议论文</span
            >
            <span
              style="margin-left: 10px; color: #999; font-size: 14px"
              v-else-if="item.articleType == 0 && item.type == 2"
              >会议PPT</span
            >
          </div>
          <div class="auther">
            <span v-if="item.mettingName" style="margin-right: 10px;margin-bottom: 10px;">{{ item.mettingName ? item.mettingName : '' }} - {{ item.authorList ? item.authorList : ''  }}</span>
            <span v-if="item.mettingTime" style="margin-right: 30px;"> {{ item.mettingTime }}</span>
            <span v-if="item.createTime">
              {{ item.createTime }}
            </span>
          </div>
          <div class="info">
            <div
              v-if="item.auditStatus !== 3"
              class="type"
              :style="item.auditStatus | colored"
            >
              {{ item.auditStatus | type }}
            </div>
            <div v-else class="type" style="color: #f32048">
              未通过-原因：{{ item.opinion }}
            </div>
            <div v-if="item.auditStatus === 2" class="read">
              &nbsp;-
              <span class="key"
                >阅读量：<span class="value">{{ item.readPv }}</span></span
              ><span class="key"
                >收藏量：<span class="value">{{ item.collectPv }}</span></span
              >
            </div>
          </div>
        </div>
        <template v-if="item.status !== 2">
          <div class="right" v-if="item.auditStatus === 2">
            <Tooltip
              @on-popper-show="ToolTipShow(item)"
              content="Bottom Center text"
              theme="light"
            >
              <div
                style="width: 140px; margin-right: 30px"
                class="edti"
                v-if="
                  (item.articleType == 0 && item.type == 0) ||
                  item.articleType == 2
                "
              >
                申请发布栏目
              </div>
              <div slot="content">
                <div class="contentTitle" v-for="(obj, i) in ShowList" :key="i">
                  {{ obj.name }}
                  <div
                    :class="sObj.status === 2 ? 'blue' : 'black'"
                    v-for="sObj in obj.childList"
                    :key="sObj.channelId1"
                    @click="setStatus(item, obj, sObj)"
                  >
                    {{ sObj.status | please }}{{ sObj.channelName }}
                  </div>
                </div>
              </div>
            </Tooltip>
            <div
              @click="asyncFamilies(item)"
              style="margin-left: 0px; width: 140px"
              :class="item.syncStatus === 0 ? 'none' : 'edti'"
            >
              {{ item.syncStatus === 0 ? "已同步至百家号" : "同步至百家号" }}
            </div>
            <div
              style="color: #fd8230; border-color: #fd8230"
              class="del"
              @click="withdrawalArticle(item)"
            >
              退领
            </div>
          </div>
          <div class="right" v-if="item.auditStatus === 0">
            <div class="none">修改</div>

            <div style="margin-right: 60px" class="none">删除</div>
          </div>
          <div class="right" v-if="item.auditStatus === 3">
            <div class="edti" @click="edit(item)">修改</div>
            <div @click="del(item)" class="del">删除</div>
          </div>
          <div class="right" v-if="item.auditStatus === 1">
            <div class="edti" @click="edit(item)">修改</div>
            <div @click="del(item)" class="del">删除</div>
          </div>
        </template>
        <template v-else>
          <div class="right">
            <div class="edti">修改</div>
            <div @click="del(item)" class="del">删除</div>
          </div>
        </template>
      </div>
      <div class="no-results" v-show="total === 0 && !spinShow">
        <img :src="require('@/assets/qietu/results.png')" alt="" />
        <p>暂无作品</p>
      </div>
    </div>
    <Paging
      :total="total"
      :curPage="page"
      :limit="7"
      :showPage="5"
      @changePager="handlePager"
    ></Paging>
  </div>
</template>

<script>
import {
  achievementList,
  apply,
  concentrationList,
  delId,
  getHYBG,
  getwenzhai,
  paperList,
  PatentsList,
  resAsyncFamilies,
  ResearchList,
  resSetStatus,
  restsList,
  science,
  standardList,
} from "../../../../../apis/user";
import Paging from "@/components/paging/index.vue";

export default {
  components: {
    Paging,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  name: "rests",
  filters: {
    type(num) {
      if (num === 2) {
        return "已发布";
      } else if (num === 0) {
        return "审核中";
      } else if (num === 3) {
        return "未通过";
      } else if (num === 1) {
        return "草稿";
      }
    },
    please(num) {
      if (num === 0) {
        return "已申请";
      } else if (num === 1) {
        return "已发布";
      } else if (num === 2) {
        return "申请";
      }
    },
    colored(num) {
      if (num === 2) {
        return "color:#999;";
      } else if (num === 0) {
        return "color:#FD8230;";
      } else if (num === 3) {
        return "color:#F32048;";
      } else if (num === 1) {
        return "color:#00A4FF;";
      }
    },
  },
  data() {
    return {
      property: 0,
      synchronizationStatus: 0,
      status: null,
      statusAct: 0,
      search: null,
      page: 1,
      Timevalue: [null, null],
      projectId: null,
      channel2Id: null,
      channel1Id: null,
      scienceList: [],
      list: [],
      total: 1,
      ShowList: [],
      spinShow: false,
    };
  },
  created() {
    this.getList();
    this.getScience();
  },
  watch: {
    name() {
      this.page = 1;
      this.search = null;
      this.Timevalue[0] = null;
      this.Timevalue[1] = null;
      this.status = null;
      this.getList();
    },
  },
  methods: {
    async withdrawalArticle(e) {
      const resp = await this.$apis.userServe.withdrawalArticle({
        articleId: e.articleId,
        articleType: e.articleType,
      });
      if (resp.resultCode == "200") {
        this.$Message.success(resp.resultDesc);
      } else {
        this.$Message.error(resp.resultDesc);
      }
      this.getList();
    },
    edit(e) {
      let { type, articleType, articleId } = e;
      if (type === 0 && articleType === 5) {
        this.$router.push(`/informationForm?id=${articleId}&title=资讯`);
      } else if (type === 0 && articleType === 0) {
        this.$router.push(`/paperForm?id=${articleId}&title=论文`);
      } else if (articleType === 3) {
        this.$router.push(`/reportForm?id=${articleId}&title=科技报告`);
      } else if (articleType === 2) {
        this.$router.push(`/monographForm?id=${articleId}&title=专著`);
      } else if (
        (articleType === 0 && type === 1) ||
        (articleType === 0 && type === 2)
      ) {
        this.$router.push(`/meetingForm?id=${articleId}&title=会议成果`);
      } else if (articleType === 0 && type === 4) {
        this.$router.push(`/otherForm?id=${articleId}&title=其他`);
      } else if (articleType === 4) {
        this.$router.push(`/standardForm?id=${articleId}&title=标准`);
      } else if (articleType === 1) {
        this.$router.push(`/patentForm?id=${articleId}&title=专利`);
      }
    },
    async del(e) {
      const obj = {
        articleId: e.articleId,
        articleType: e.articleType,
        auditStatus: e.auditStatus,
      };
      console.log(obj);
      const res = await delId(obj);
      await this.getList();
      if (res) {
        console.log(res);
      }
    },
    async filterFunc(obj) {
      let res = {};
      if (this.name === "期刊论文") {
        res = await paperList(obj);
      } else if (this.name === "会议成果") {
        res = await achievementList(obj);
      } else if (this.name === "专利") {
        res = await PatentsList(obj);
      } else if (this.name === "专著") {
        res = await concentrationList(obj);
      } else if (this.name === "科技报告") {
        res = await ResearchList(obj);
      } else if (this.name === "标准") {
        res = await standardList(obj);
      } else if (this.name === "其他") {
        res = await restsList(obj);
      } else if (this.name === "文摘") {
        res = await getwenzhai(obj);
      } else if (this.name === "行业报告") {
        res = await getHYBG(obj);
      }
      console.log(res);
      return res;
    },
    async asyncFamilies(e) {
      if (e.syncStatus === 0) return;
      console.log(e);
      const obj = {
        articleId: e.articleId,
        articleType: e.articleType,
        title: e.title,
      };
      const res = await resAsyncFamilies(obj);
      if (res) {
        await this.getList();
      }
      console.log(res);
    },
    async setStatus(item, obj, sobj) {
      console.log(item, obj, sobj);
      let resObj = {
        articleId: item.articleId,
        articleType: item.articleType,
        projectId: obj.projectId,
        channelId1: sobj.channelId1 ? sobj.channelId1 : null,
        channelId2: sobj.channelId2 ? sobj.channelId2 : null,
      };
      console.log(resObj);
      const res = await resSetStatus(resObj);
      if (res) {
        await this.ToolTipShow(item);
      }
      console.log(res);
    },
    async ToolTipShow(e) {
      console.log(e);
      const obj = {
        articleId: e.articleId,
        articleType: e.articleType,
      };
      const { data } = await apply(obj);
      this.ShowList = data;
      const Tabel = JSON.parse(sessionStorage.getItem("Table")).PlatformEnum;
      this.ShowList.map((e) => {
        Tabel.forEach((item) => {
          if (e.projectId === item.id) {
            console.log(item);
            e["name"] = item.name;
          }
        });
      });
      console.log(this.ShowList, " this.ShowLits ");
    },
    handlePager(e) {
      this.page = e;
      this.getList();
    },
    async getScience() {
      let res = await science();
      this.scienceList = res.data;
      console.log(this.scienceList, "this.scienceList");
    },
    async getList() {
      let obj = {
        pageNo: this.page,
        pageSize: 7,
        title: this.search,
        beginTime: this.Timevalue[0],
        endTime: this.Timevalue[1],
        status: this.status,
        synchronizationStatus: this.synchronizationStatus,
      };
      this.spinShow = true;
      let { data } = await this.filterFunc(obj);
      let { total } = data;
      this.total = Number(total);
      this.list = data.list;
      // 处理后端返回的数据带null情况
      this.list.map(item => {
        if(item.authorList) {
          let arr = item.authorList.split(',')
          arr = arr.filter(val => val !== 'null')
          item.authorList = arr.join(',')
        }
        if(item.mettingTime) {
          let arr = item.mettingTime.split('第')
          arr = arr.filter(val => !val.includes('null'))
          item.mettingTime = arr.join('第')
          if(item.mettingTime && !item.mettingTime.includes('年')) {
            item.mettingTime = '第' + item.mettingTime
          }
        }
      })
      scrollTo(0, 0);
      this.spinShow = false;
    },
    handleClick() {
      this.page = 1;
      this.getList();
    },
    handleChange(value) {
      console.log(value);
      this.page = 1;
      this.getList();
    },
    setSropertyAct(i, type) {
      this.property = i;
      console.log(type);
      if (typeof type === "number") {
        this.synchronizationStatus = 1;
      } else {
        type.projectId
          ? (this.projectId = type.projectId)
          : (this.projectId = null);
        type.channel1Id
          ? (this.channel1Id = type.channel1Id)
          : (this.projectId = null);
        type.channel2Id
          ? (this.channel2Id = type.channel2Id)
          : (this.projectId = null);
      }
      this.getList();
    },
    setStatusAct(i, status) {
      this.statusAct = i;
      this.status = status;
      this.page = 1;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.contentTitle {
  margin-bottom: 20px;
  font-size: 14px;
  width: 100px;
  //font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24px;
  color: #333333;
  cursor: pointer;

  .blue {
    //height: 24px;
    font-size: 12px;
    line-height: 24px;
    //font-family: Microsoft YaHei;
    font-weight: 400;
    color: #183ed7;
  }

  .black {
    //height: 13px;
    font-size: 12px;
    line-height: 24px;
    //font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
}

#rests {
  margin-left: 40px;
  margin-right: 40px;

  .classify {
    display: flex;
    height: 30px;
    justify-content: space-between;
    width: 100%;

    .searchinput {
      width: 260px;
      border-radius: 10px;
    }
    .elHidden {
      width: 0;
      opacity: 0;
    }

    .right {
      height: 30px;

      .time {
        //background: yellow;
        line-height: 30px;
        margin-right: 5px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }

    .left {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      cursor: pointer;

      .status {
        margin-right: 3px;
      }

      .statusList {
        margin-left: 10px;
        margin-right: 10px;
      }

      .act {
        margin-left: 10px;
        margin-right: 10px;
        color: #00a4ff;
      }
    }
  }

  .list {
    position: relative;
    margin-top: 30px;
    // height: 400px;
    //background: pink;
    .box:hover .title {
      color: #00a4ff;
    }
    .box {
      //height: 122px;
      display: flex;
      padding-top: 30px;
      padding-bottom: 30px;
      //flex-wrap: wrap;
      //background: pink;
      border-top: 1px dashed #d6d6d6;
      border-bottom: 1px dashed #d6d6d6;
      justify-content: space-between;

      .left {
        width: 900px;
        .info {
          display: flex;
          align-items: center;
          margin-top: 15px;
        }
        .read {
          //margin-bottom: 30px;
          width: 560px;
          color: rgb(153, 153, 153);
          .key {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgb(153, 153, 153);
            margin-right: 25px;
          }
        }
        .title {
          cursor: pointer;
          //height: 21px;
          font-size: 20px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          //color: #00A4FF;
        }

        .auther {
          display: flex;
          flex-wrap: wrap;
          margin-top: 8px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999999;
        }
      }

      .right {
        margin-top: 20px;
        display: flex;

        .edti {
          cursor: pointer;
          width: 82px;
          color: #00a4ff;
          height: 34px;
          border: 1px solid #00a4ff;
          border-radius: 10px;
          text-align: center;
          line-height: 34px;
        }

        .none {
          margin-left: 30px;
          cursor: pointer;
          color: #999999;
          border: 1px solid #999999;
          width: 82px;
          height: 34px;
          border-radius: 10px;
          //margin-left: 30px;
          text-align: center;
          line-height: 34px;
        }

        .del {
          margin-left: 30px;
          cursor: pointer;
          color: #f32048;
          //margin-right: 20px;
          text-align: center;
          line-height: 34px;
          width: 82px;
          margin-right: 60px;
          height: 34px;
          border: 1px solid #f32048;
          border-radius: 10px;
        }
      }

      //height: 400px;
    }
    .no-results {
      margin: 0 auto;
      width: 500px;
      // height: 300px;
      img {
        width: 100%;
      }
      p {
        text-align: center;
        font-size: 16px;
        color: #999999;
      }
    }
  }
}
</style>
